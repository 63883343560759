import React from 'react'
import * as S from './style'
import YourCompleteJourneyJSON from '../../../assets/data/your-complete-journey.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type YourCompleteJourneyProps = {
  title: string;
  description: string;
  image: string;
  link: string;
}

const XL = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleDataLayer = (title: string, url: string) => {
    sendDatalayerEvent({
      section: 'section_06',
      section_name: 'Sua viagem completa',
      element_action: 'click button',
      element_name: title,
      redirect_url: url,
    })
  }

  return (
    <>
      {YourCompleteJourneyJSON.slice(0, 4).map((item: YourCompleteJourneyProps) => (
        <a
          key={item.title}
          href={item.link}
          onClick={() => handleDataLayer(item.title, item.link)}
        >
          <S.CardImage className={`${'col-lg-4 col-xl-3 mb-lg-3'}`}>
            <S.Card style={{ backgroundImage: `url(${item.image})` }} className='mb-3 px-4'>
              <div className='text pr-3 pr-lg-4'>
                <h2 className='fs-20 lh-25 text-white'>{item.title}</h2>
                <p className='fs-16 lh-19 text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </S.Card>
          </S.CardImage>
        </a>
      ))}
      <div className='offset-xl-1'>
        {YourCompleteJourneyJSON.slice(4, 7).map((item: YourCompleteJourneyProps) => (
          <a
            key={item.title}
            href={item.link}
            onClick={() => handleDataLayer(item.title, item.link)}
          >
            <S.CardImage className={`${'col-lg-4 col-xl-3 mb-lg-3 mr-xl-4'}`}>
              <S.Card style={{ backgroundImage: `url(${item.image})` }} className='mb-3 px-4'>
                <div className='text pr-3 pr-lg-4'>
                  <h2 className='fs-20 lh-25 text-white'>{item.title}</h2>
                  <p className='fs-16 lh-19 text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </S.Card>
            </S.CardImage>
          </a>
        ))}
      </div>
    </>
  )
}

export default XL
